import React, { useEffect, useState } from 'react'
import * as conversionLib from 'lisecutilityfunctions/lib/commonfunction';
import { LiTable } from 'lisecdependantcomp';
import { Redirect } from 'react-router-dom';
import { LiBreadCrumbs, LiButton, LiComboBox, LiLineEdit, LiReactDialog, LiStatusManager, LiFileUpload, LiComboList } from 'liseccomponents';
import breadcrumbsimg from '../images/dashboard.svg'
import { OpenApiClient_analytics_v1, getSiteName, OpenApiBody } from 'lisecutilityfunctions/lib/openapi';
import * as OpenApiConst from 'lisecutilityfunctions/lib/openapi'
import useWindowSize from '../hooks/useWindowSize';
import FilterPanel from '../filters/filterpanel';
import FilterpanelXS from '../filters/filterpanelsmalldevice';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { setSelectedDate, setSelectedMachine, setAddNewMachineLine } from '../actions/filteractions';
import { LiMultiterm, getMultitermTexts } from 'lisecutilityfunctions/lib/utilitylib';
import LiTreeList from './treelist'

import '../machinesandlines/css/machinesandlines.css'
import '../css/oeedashboard.css'
import '../filtercomponents/filter.css'
import { initiateDevExpressLang } from '../../oeedashboard/utility/utilitydevexpress';

let siteName = getSiteName();

const ShowDialog = ({setOpenPopUp}) => {
    return (
        <LiReactDialog
            onClose={() => setOpenPopUp(false)}
            title={<LiMultiterm textId="99032728" textVal="Alarm categorisation" />}
            hasCloseIcon={true}
            isResizable={false}
            isDraggable={false}
            modal={true}
            buttons={
                [
                    {
                        text: <LiMultiterm textId="99000240" textVal="Close" />,
                        onClick: () => setOpenPopUp(false),
                        className: "LiGeneral_raisedButton"
                    }
                ]
            }
        >
            <div style={{textAlign: 'center', marginTop: '120px'}}>
                <LiMultiterm textId="99034227" textVal="Alarm categorisation is not available for lines"></LiMultiterm>
            </div>
        </LiReactDialog>
    )
}


function AlarmCategoryManagement(props) {
    const [alarmData, setAlarmData] = useState([])
    const [alarmType, setAlarmTypeID] = useState({})
    const [revAlarmType, setRevAlarmTypeID] = useState(null)
    const [lookupDataForModifiedAlarmTypeId, setLookupDataForModifiedAlarmTypeId] = useState([])
    const [localList, setLocalList] = useState([])
    const [colorComapreTexts, setColorComapreTexts] = useState([])
    const [alarmText, setAlarmText] = useState("")
    const [alarmCategory, setAlarmCategory] = useState("");
    const [openPopUp, setOpenPopUp] = useState(false)

    const language = conversionLib.getLocalStorageValue("core.app.language.id3")
    initiateDevExpressLang(language);

    const oeedashboardObj = {
        "OEEDashBoard": {
            "label": <LiMultiterm textId="99032728" textVal="Alarm categorisation" />,
            "path": "#"
        }
    }

    const themeName = conversionLib.getLocalStorageValue("core.app.theme") !== null ? (conversionLib.getLocalStorageValue("core.app.theme")) : "LightTheme";
    const size = useWindowSize()


    /**
    * @description Called only once when the component is loaded
    */
    useEffect(() => {
        OpenApiClient_analytics_v1.getClient(siteName).GET_AlarmType(callbackGetAlarmType);
    }, [])


    /**
    * @description Called everytime whenever there is a change in the selectedMachine ot alarmType
    */
    useEffect(() => {
        if(props.filterStore.selectedMachine){
            props.loader.showLoader({ "GET_AlarmTypeSetting_siteId_machineId": true })
            setAlarmData([])
            if (isNaN(parseInt(props.filterStore.selectedMachine)) === true) {
                OpenApiClient_analytics_v1.getClient(siteName).GET_AlarmTypeSetting_siteId_machineId(callbackGetAlarmTypeSetting, 1, props.filterStore.selectedMachine);
            }else if(isNaN(parseInt(props.filterStore.selectedMachine)) === false){
                setOpenPopUp(true);
                props.loader.showLoader({ "GET_AlarmTypeSetting_siteId_machineId": false })
            }
        }
    }, [props.filterStore.selectedMachine, alarmType])

    /**
       * @description Making data to string as dev express only supports that
       */
    let callbackGetAlarmTypeSetting = (responseData) => {
        let textNumbers = [];
        let requiredMultiterm = [99001948, 99031855, 99032492, 6002000, 99032724]
        if (responseData.state.response.status === 200) {
            let jsonData = conversionLib.convertToJSON(responseData.state.response.data);
            jsonData.forEach(data => {
                data.ID = String(data.id);
                data.machineId = String(data.machineId);
                data.messageTextNo = String(data.messageTextNo);
                data.alarmTypeId = String(data.alarmTypeId);
                data.modifiedAlarmTypeId = String(data.modifiedAlarmTypeId);
                data.whoTextNo = String(data.whoTextNo);
                data.siteId = String(data.siteId);
                textNumbers.push(data.messageTextNo, data.whoTextNo)
            })
            textNumbers = [...new Set(textNumbers), ...requiredMultiterm]
            setLocalList(JSON.parse(JSON.stringify(jsonData)))
            manipulateData(jsonData, textNumbers)
        }
        else {
            // let errorData = conversionLib.convertToJSON(responseData?.state?.response?.data);
            // LiStatusManager.showStatus(responseData.state.response.status, errorData.title);
        }
        props.loader.showLoader({ "GET_AlarmTypeSetting_siteId_machineId": false })
    }

    /**
   * @description Creating Lookup data for the modified alarm type in treelist
   */
    let callbackGetAlarmType = (responseData) => {
        let obj = {}
        let revObj = {}
        let tempArrForAlarmTypeId = [];
        let tempArrForForModifiedAlarmTypeId = [];
        if (responseData.state.response.status === 200) {
            let jsonData = conversionLib.convertToJSON(responseData.state.response.data);
            for (let i = 0; i < jsonData.length; i = i + 1) {
                if (obj && jsonData && jsonData[i]) {
                    obj[jsonData[i].id] = jsonData[i].name
                    revObj[jsonData[i].name] = jsonData[i].id
                    tempArrForAlarmTypeId[i] = { "alarmTypeId": jsonData[i].name }
                    tempArrForForModifiedAlarmTypeId[i] = { "modifiedAlarmTypeId": jsonData[i].name }
                }
            }
            setRevAlarmTypeID(revObj)
            setAlarmTypeID(obj)
            // setLookupDataForModifiedAlarmTypeId(tempArrForForModifiedAlarmTypeId)
        }
        else {
            LiStatusManager.showStatus(responseData.state.response.status, responseData.state.response.message);
        }
    }

    /**
     * @param {object} jsonData The server response.
     * @param {array} textNumbers The Multitrems Array.
     * @return {number} This function replaces the multiterm number with texts.
     */function bigThing(jsonData, textNumbers) {
        // ...
    }
    /**
   * @description This function replaces the multiterm number with texts.
   */
    let manipulateData = async (jsonData, textNumbers) => {
        let textsObj = {}
        let alarmList = jsonData;
        var tempArrayofText = await getMultitermTexts(textNumbers)
        let texts = conversionLib.convertToJSON(tempArrayofText.state.response.data)
        let colorNumbers = []
        for (let i = 0; i < texts.length; i = i + 1) {
            if (textsObj && texts && texts[i]) {
                textsObj[texts[i].number] = texts[i].text
            }
            if (parseInt(texts[i].number) === 99031855 || parseInt(texts[i].number) === 99001948 || parseInt(texts[i].number) === 99032492) {
                colorNumbers.push(texts[i])
            }
        }
        setAlarmText(textsObj["6002000"] !== undefined ? textsObj["6002000"] : "Alarm Text")
        setAlarmCategory(textsObj["99032724"] !== undefined ? textsObj["99032724"] : "Alarm Caterogy")
        setColorComapreTexts(colorNumbers)
        // setTextsObj(textsObj)
        if (alarmList.length > 0) {
            alarmList.forEach(data => {
                data.messageTextNo = textsObj[data.messageTextNo];
                data.whoTextNo = textsObj[data.whoTextNo];
                if (parseInt(data.alarmTypeId) === 1) {
                    // data.alarmTypeId = alarmType[data.alarmTypeId]
                    data.alarmTypeTranslated = textsObj["99031855"]
                } else if (parseInt(data.alarmTypeId) === 2) {
                    // data.alarmTypeId = alarmType[data.alarmTypeId]
                    data.alarmTypeTranslated = textsObj["99001948"]
                }
                if (parseInt(data.modifiedAlarmTypeId) === 1) {
                    data.modifiedAlarmTypeId = textsObj["99031855"]
                    // data.modifiedAlarmTypeId = alarmType[data.modifiedAlarmTypeId]
                } else if (parseInt(data.modifiedAlarmTypeId) === 2) {
                    data.modifiedAlarmTypeId = textsObj["99001948"]
                    // data.modifiedAlarmTypeId = alarmType[data.modifiedAlarmTypeId]
                } else if (parseInt(data.modifiedAlarmTypeId) === 3) {
                    data.modifiedAlarmTypeId = textsObj["99032492"]
                    // data.modifiedAlarmTypeId = alarmType[data.modifiedAlarmTypeId]
                }
            })
            setAlarmData(alarmList)
            let tempLookUpData = [{ "modifiedAlarmTypeId": textsObj["99001948"] }, { "modifiedAlarmTypeId": textsObj["99031855"] }];
            setLookupDataForModifiedAlarmTypeId(tempLookUpData)
        }
    }
    // console.log(lookupDataForModifiedAlarmTypeId);

    /**
    * @description Geting the result back from the treelist
    */
    const getResult = (value) => {
        let textsObj = {};
        let tempArr = [];
        for (let i = 0; i < colorComapreTexts.length; i = i + 1) {
            if (textsObj && colorComapreTexts && colorComapreTexts[i]) {
                textsObj[colorComapreTexts[i].number] = colorComapreTexts[i].text
            }
        }
        const cloneAlarmData = JSON.parse(JSON.stringify(localList))
        const updateValueClone = JSON.parse(JSON.stringify(value))
        updateValueClone.forEach(data => {
            if (String(data.modifiedAlarmTypeId) === textsObj["99001948"]) {
                data.modifiedAlarmTypeId = "2"
            } else if (String(data.modifiedAlarmTypeId) === textsObj["99031855"]) {
                data.modifiedAlarmTypeId = "1"
            }
        })

        //checking the changed data
        cloneAlarmData.forEach(data => {
            let serverData = String(data.modifiedAlarmTypeId)
            updateValueClone.forEach(innerData => {
                let newData = String(innerData.modifiedAlarmTypeId)
                if (data.id === innerData.id) {
                    if (newData !== serverData) {
                        tempArr.push(innerData)
                    }
                }
            })
        })

        tempArr.forEach(data => {
            delete data.ID;
            props.loader.showLoader({ "PUT_AlarmTypeSetting_siteId_machineId_id": true })
            OpenApiClient_analytics_v1.getClient(siteName).PUT_AlarmTypeSetting_siteId_machineId_id(callbackPutAlarmTypeSetting, new OpenApiBody(OpenApiConst.MimeTypes.MIME_APPLICATION_JSON, data), 1, props.filterStore.selectedMachine, data.id);
        })
    }

    let machineChainConfig = [{ id: "messageTextNo", caption: alarmText }, { id: "modifiedAlarmTypeId", caption: alarmCategory }]

    const configObject = {
        dragDrop: true,
        editing: true,
        columnSearch: true,
        multiSelect: true,
        pagination: false,
        defaultNumberOfRows: 10,
        pageSizes: [5, 10, 15],
        expandedRowKeys: [-1], //Set -1 if don't want anyone already expanded
        headers: machineChainConfig
    }

    let callbackPutAlarmTypeSetting = (responseData) => {
        if (responseData.state.response.status === 200) {
            let jsonData = conversionLib.convertToJSON(responseData.state.response.data);

        }
        else {
            LiStatusManager.showStatus(responseData.state.response.status, responseData.state.response.message);
        }
        props.loader.showLoader({ "PUT_AlarmTypeSetting_siteId_machineId_id": false })
    }

    return (
        <div className='OEEDashboard_mainContainer'>
             {openPopUp && <ShowDialog setOpenPopUp={setOpenPopUp} />}
            <div className={`OEE_Breadcrumbs ${props.hide ? "BreadCrumbHeaderHide" : ""}`}>
                <LiBreadCrumbs breadcrumbsdataObj={oeedashboardObj} />
            </div>


            <div className='OEEDashboard_layoutSidebarContainer'>
                <FilterpanelXS showMachines={true} showRefresh={false} showCalender={false} showShifts={false} loader={props.loader} />
                <div id="OEEDashboard_Layout_Div" className="OEEDashboard_Layout_Div">
                    <div className='AlarmCategorySpace'><br></br></div>
                    < LiTreeList id="machine" colorComapreTexts={colorComapreTexts} result={getResult} lookupData={[lookupDataForModifiedAlarmTypeId]} config={configObject} data={(alarmData)} />
                    <div><br></br></div>
                </div>
                <FilterPanel showMachines={true} showRefresh={false} showCalender={false} showShifts={false} loader={props.loader} setHide={props.setHide} />
            </div>

            {/* <div className='OEEDashboard_calendarDiv'>Calendar Component</div> */}
        </div>

       

    )
}

function mapStateToProps(state) {
    return {
        filterStore: state.filterStore
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setSelectedDate,
        setAddNewMachineLine,
        setSelectedMachine
    }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(AlarmCategoryManagement);