import React, { useEffect, useState } from 'react'
import * as conversionLib from 'lisecutilityfunctions/lib/commonfunction';
import { Redirect } from 'react-router-dom';
import { LiBreadCrumbs, LiButton, LiComboBox, LiLineEdit, LiReactDialog, LiStatusManager, LiTooltip3, LiComboList } from 'liseccomponents';
import { OpenApiClient_ng_user_management_v1, OpenApiClient_analytics_v1, OpenApiClient_calendar_v1, getSiteName, OpenApiBody } from 'lisecutilityfunctions/lib/openapi';
import * as OpenApiConst from 'lisecutilityfunctions/lib/openapi'
import FilterPanel from '../filters/filterpanel';
import FilterpanelXS from '../filters/filterpanelsmalldevice';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { setSelectedDate, setSelectedMachine, setAddNewMachineLine, setMachineGroupList, setMachineList, setRecentList } from '../actions/filteractions';
import { LiMultiterm, mapEnumUNIT } from 'lisecutilityfunctions/lib/utilitylib';
import * as FilterActions from '../actions/filteractions'
import './css/machinesandlines.css'
import './css/comboheight.css'
import '../css/oeedashboard.css'
import machineImage from "./images/machinePicture.jpg"

let siteName = getSiteName();

function MachineAndLineManagement(props) {
    const [machineData, setMachineData] = useState({ siteId: 1 })
    const [machineList, setMachineList] = useState([])
    const [showMachine, setShowMachine] = useState(false)
    const [showMachineGroup, setShowMachineGroup] = useState(false)
    const [image, setImage] = useState(machineImage)
    const [machineToSelectPopUp, setMachinesToSelectPopUp] = useState(false)
    const [selectedMachines, setSelectedMachines] = useState([])
    const [newMachineIndex, setNewMachineIndex] = useState(1)
    const [redirect, setRedirect] = useState(false)
    const [showDeletePopUp, setShowDeletePopUp] = useState(false)
    const [showAddMachineMemberPopUp, setShowAddMachineMemberPopUp] = useState(false)
    const [timeDropDownOptions, setTimeDropDownOptions] = useState([])
    const [areaDropDownOptions, setAreaDropDownOptions] = useState([])
    const [weightDropDownOptions, setweightDropDownOptions] = useState([])
    const [calNoOptionsDropDown, setCalOptions] = useState([])
    const [dataToCompare, setDataToCompare] = useState([])
    const [showWarning, setShowWarning] = useState(false)

    const initialState = { siteId: 1, name: "", groupId: "", downtimeThreshold: "", areaThreshold: "", partThreshold: "", weightThreshold: "", machineGroupId: "", machineId: "", machineModel: "", machineName: "", machineSeq: [], machineImage: undefined }

    const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
    });

    const oeedashboardObj = {
        "OEEDashBoard": {
            "label": <LiMultiterm textId="99032727" textVal="Machine And Line Management" />,
            "path": "#"
        }
    }

    //Whenever there is a change in the selected machine based on the machine type we make the get call for machine or group
    useEffect(() => {
        props.loader.showLoader({ "GET_Machine": true })
        setDataToCompare(initialState)
        if (props.filterStore.addNewMachineLine) {
            props.loader.showLoader({ "GET_Machine": false })
            setShowMachine(false)
            setShowMachineGroup(true)
            setMachineData(initialState)
            setSelectedMachines([])
        } else if (!props.filterStore.addNewMachineLine) {
            setMachineData(initialState)
            if (isNaN(parseInt(props.filterStore.selectedMachine))) {
                OpenApiClient_analytics_v1.getClient(siteName).GET_Machine_siteId_machineId(getMachineGroupCallback, 1, props.filterStore.selectedMachine);
            } else {
                OpenApiClient_analytics_v1.getClient(siteName).GET_MachineGroup_siteId_groupId((res) => getMachineGroupCallback(res, "MACHINE_GROUP"), 1, props.filterStore.selectedMachine);
            }
        }
    }, [props.filterStore.selectedMachine, props.filterStore.addNewMachineLine])

    useEffect(() => {
        OpenApiClient_analytics_v1.getClient(siteName).GET_MachineGroup_siteId_AvaliableMachine(getMachineCallback, 1);
        OpenApiClient_analytics_v1.getClient(siteName).GET_MachineAttributeUnit_Time((res) => getDropDownOptions(res, "Time"));
        OpenApiClient_analytics_v1.getClient(siteName).GET_MachineAttributeUnit_Weight((res) => getDropDownOptions(res, "Weight"));
        OpenApiClient_analytics_v1.getClient(siteName).GET_MachineAttributeUnit_Area((res) => getDropDownOptions(res, "Area"));
        OpenApiClient_calendar_v1.getClient(siteName).GET_Calendars_calType(callbackMasterDataList, "individual");
    }, [])

    let callbackMasterDataList = (responseData) => {
        if (responseData.state.response.status === 200) {
            let jsonData = conversionLib.convertToJSON(responseData.state.response.data);
            setCalOptions(jsonData)
        }
        else {
            LiStatusManager.showStatus(responseData.state.response?.status, responseData.state.response?.message);
        }
    }

    let getDropDownOptions = (responseData, type) => {
        if (responseData.state.response.status === 200) {
            let jsonData = conversionLib.convertToJSON(responseData.state.response.data);
            if (type === "Time") {
                setTimeDropDownOptions(jsonData)
            } else if (type === "Weight") {
                setweightDropDownOptions(jsonData)
            } else if (type === "Area") {
                setAreaDropDownOptions(jsonData)
            }
        }
        else {
            LiStatusManager.showStatus(responseData.state.response?.status, responseData.state.response?.message);
        }
    }

    let getMachineCallback = (responseData) => {
        if (responseData.state.response.status === 200) {
            let jsonData = conversionLib.convertToJSON(responseData.state.response.data);
            setMachineList(jsonData)
        }
        else {
            LiStatusManager.showStatus(responseData.state.response?.status, responseData.state.response?.message);
        }
    }

    let getMachineGroupCallback = (responseData, type) => {
        if (responseData.state.response.status === 200) {
            let jsonData = conversionLib.convertToJSON(responseData.state.response.data);
            if (type === "MACHINE_GROUP") {
                jsonData.machineId = jsonData.groupId
                jsonData.machineName = jsonData.name
                window.history.pushState("", "", `${window.location.origin}${window.location.pathname}?machineId=${jsonData?.machineId}`);

                setSelectedMachines(jsonData.machineSeq === undefined ? [] : jsonData.machineSeq)
                jsonData.machineSeq !== undefined && jsonData.machineSeq.sort((a, b) => { return a.seq - b.seq })
                setMachineData(jsonData)
                setShowMachineGroup(true)
                setShowMachine(false)
            } else {
                setMachineData(jsonData)
                setShowMachineGroup(false)
                setShowMachine(true)
            }
            setDataToCompare(jsonData)
        }
        else {
            LiStatusManager.showStatus(responseData.state.response?.status, responseData.state.response?.message);
        }
        props.loader.showLoader({ "GET_Machine": false })
    }

    let handleChange = (e) => {
        let tempData = JSON.parse(JSON.stringify(machineData));
        tempData[e.target.name] = e.target.value
        setMachineData(tempData)
    }

    let handleSearch = () => {

    }

    let deletedMachine = []

    let handleDelete = (machine) => {
        let machineToDelete = machine;
        let newMachineData = selectedMachines.filter(m => machineToDelete.machineId !== m.machineId)
        setSelectedMachines(newMachineData)
        deletedMachine = newMachineData
        if(!props.filterStore.addNewMachineLine) {
            handleSubmit("COMBO", deletedMachine)
        }
    }

    let handleSubmit = (e = "COMBO", deletedMachineFromFunction = selectedMachines) => {
        if ((e === "COMBO") || (String(dataToCompare[e.target.name]) !== String(e.target.value))) {
            setDataToCompare(machineData)
            if (showMachine) {
                let machineDataToUpdate = machineData;
                if (machineDataToUpdate.machineGroupId !== undefined) {
                    machineDataToUpdate.calNo = null;
                }
                OpenApiClient_analytics_v1.getClient(siteName).PUT_Machine_siteId_machineId(putMachineCallback, new OpenApiBody(OpenApiConst.MimeTypes.MIME_APPLICATION_JSON, machineData), 1, props.filterStore.selectedMachine);
            } else if (showMachineGroup) {
                let machineSeq = []
                let machineObj = {}
                if (deletedMachineFromFunction.length > 0) {
                    if (deletedMachine.length > 0) {
                        deletedMachine.forEach((selectedMachine, idx) => {
                            machineObj = {
                                machineId: selectedMachine.machineId,
                                seq: idx
                            }
                            machineSeq.push(machineObj);
                        })
                    } else {
                        selectedMachines.forEach((selectedMachine, idx) => {
                            machineObj = {
                                machineId: selectedMachine.machineId,
                                seq: idx
                            }
                            machineSeq.push(machineObj);
                        })
                    }
                }
                deletedMachine = []
                let data = machineData;
                if (data.name.length !== 0) {
                    data.machineSeq = machineSeq;
                    if (props.filterStore.addNewMachineLine) {
                        if (data.machineSeq.length > 0 && data.name !== "") {
                            OpenApiClient_analytics_v1.getClient(siteName).POST_MachineGroup(postMachineGroupCallback, new OpenApiBody(OpenApiConst.MimeTypes.MIME_APPLICATION_JSON, data));
                        } else {
                            props.loader.showLoader({ "PUT_Machine": false })
                            setShowAddMachineMemberPopUp(true)
                        }
                    } else {
                        OpenApiClient_analytics_v1.getClient(siteName).PUT_MachineGroup_siteId_groupId(putMachineGroupCallback, new OpenApiBody(OpenApiConst.MimeTypes.MIME_APPLICATION_JSON, data), 1, props.filterStore.selectedMachine);
                    }
                } else {
                    setShowWarning(true)
                }
            }
        }
    }

    let postMachineGroupCallback = (responseData) => {
        if (responseData.state.response.status === 200 || responseData.state.response.status === 201) {
            OpenApiClient_analytics_v1.getClient(siteName).GET_MachineGroup_siteId(getMachineGroupListCallback, 1);
            let jsonData = conversionLib.convertToJSON(responseData.state.response.data);
            jsonData.machineId = jsonData.groupId
            jsonData.machineName = jsonData.name

            props.setAddNewMachineLine(false)
            let recentList = JSON.parse(sessionStorage.getItem("recentList"));
            recentList.unshift(jsonData)
            window.history.pushState("", "", `${window.location.origin}${window.location.pathname}?machineId=${jsonData?.machineId}`);
            props.setRecentList(recentList)
            sessionStorage.setItem('recentList', JSON.stringify(recentList))
            if(String(props.filterStore.selectedMachine) !== String(jsonData.groupId)){
                setTimeout(()=>{
                    props.setSelectedMachine(jsonData.groupId)
                },500)
            }
        }
        else {
            // LiStatusManager.showStatus(responseData.state.response?.status, responseData.state.response?.message);
        }
    }

    let putMachineGroupCallback = (responseData, type) => {
        props.loader.showLoader({ "PUT_Machine": false })
        if (responseData.state.response.status === 200) {
            OpenApiClient_analytics_v1.getClient(siteName).GET_MachineGroup_siteId(getMachineGroupListCallback, 1);
            OpenApiClient_analytics_v1.getClient(siteName).GET_MachineGroup_siteId_AvaliableMachine(getMachineCallback, 1);
        }
        else {
            LiStatusManager.showStatus(responseData.state.response?.status, responseData.state.response?.message);
        }
    }

    let putMachineCallback = (responseData, type) => {
        props.loader.showLoader({ "PUT_Machine": false })
        if (responseData.state.response.status === 200) {
            OpenApiClient_analytics_v1.getClient(siteName).GET_Machine(getMachineListCallback, 1);
        }
        else {
            LiStatusManager.showStatus(responseData.state.response?.status, responseData.state.response?.message);
        }
    }

    let getMachineGroupListCallback = (responseData) => {
        if (responseData.state.response.status === 200) {
            let jsonData = conversionLib.convertToJSON(responseData.state.response.data);
            props.setMachineGroupList(jsonData)
        }
        else {
            LiStatusManager.showStatus(responseData.state.response.status, responseData.state.response.message);
        }
        props.loader.showLoader({ "PUT_Machine": false })
    }

    let getMachineListCallback = (responseData) => {
        if (responseData.state.response.status === 200) {
            let jsonData = conversionLib.convertToJSON(responseData.state.response.data);
            let lastObjectMachine = jsonData[jsonData.length - 1];
            jsonData.machineId = lastObjectMachine.machineId;
            jsonData.machineName = lastObjectMachine.machineName;

            let recentList = JSON.parse(sessionStorage.getItem("recentList"));
            recentList.unshift(lastObjectMachine);
            sessionStorage.setItem('recentList', JSON.stringify(recentList))
            props.setMachineList(jsonData)
        }
        else {
            LiStatusManager.showStatus(responseData.state.response.status, responseData.state.response.message);
        }
        props.loader.showLoader({ "PUT_Machine": false })
    }

    //Uploading The Image And Setting It In The State
    let imageHandler = (e) => {
        const reader = new FileReader();
        reader.onload = () => {
            if (reader.readyState === 2) {
                setImage(reader.result)
                let tempData = JSON.parse(JSON.stringify(machineData));
                let machineRawImage = reader.result.split(",")
                tempData["machineImage"] = machineRawImage[1]
                machineData.machineImage = machineRawImage[1]
                setMachineData(tempData)
                if(!props.filterStore.addNewMachineLine) {
                    handleSubmit()
                }
            }
        }
        reader.readAsDataURL(e.target.files[0])
    };

    let showMachineList = (machine, idx) => {
        setNewMachineIndex(idx)
        if (machineToSelectPopUp) {
            setMachinesToSelectPopUp(false)
        } else {
            setMachinesToSelectPopUp(true)
        }
    }



    let handleDeleteMachineGroupPopUp = () => {
        if (showDeletePopUp) {
            setShowDeletePopUp(false)
        } else {
            setShowDeletePopUp(true)
        }
    }

    let closeMachineMemberPopUp = () => {
        setShowAddMachineMemberPopUp(false)
    }

    let handelDeleteCall = () => {
        props.loader.showLoader({ "Delete": true })
        if (showMachineGroup) {
            OpenApiClient_analytics_v1.getClient(siteName).DELETE_MachineGroup_siteId_groupId(deleteCallback, 1, props.filterStore.selectedMachine);
        }
    }

    let deleteCallback = (responseData, type) => {
        if (responseData?.state?.response?.status === 200) {
            // let jsonData = conversionLib.convertToJSON(responseData.state.response.data);
            OpenApiClient_analytics_v1.getClient(siteName).GET_MachineGroup_siteId(getMachineGroupListCallback, 1);
            let recentList = JSON.parse(sessionStorage.getItem("recentList"));
            let filteredRecent = recentList.filter(data => (String(data?.groupId) !== String(props.filterStore.selectedMachine)));
            let filteredRecentList = filteredRecent.filter(item => item !== null);
            if(filteredRecentList?.length > 0){
                props.setSelectedMachine(filteredRecentList[0].machineId)
                window.history.pushState("", "", `${window.location.origin}${window.location.pathname}?machineId=${filteredRecentList[0].machineId}`);
            }else if(filteredRecentList.length <= 0){
                props.setSelectedMachine(machineList[0].machineId);
                window.history.pushState("", "", `${window.location.origin}${window.location.pathname}?machineId=${machineList[0].machineId}`)
            }
            

            props.setRecentList(filteredRecentList)
            sessionStorage.setItem("recentList", JSON.stringify(filteredRecentList));
        }
        else {
            LiStatusManager.showStatus(responseData.state.response?.status, responseData.state.response?.message);
        }
        setShowDeletePopUp(false)
        props.loader.showLoader({ "Delete": false })
    }
    let handleCancel = () => {
        setRedirect(false)
        props.setAddNewMachineLine(false)
    }

    let timeComboOptions = () => {
        return timeDropDownOptions.map(data => {
            return <LiComboList key={data} value={data}>{mapEnumUNIT[data]}</LiComboList>
        })
    }

    let calNoOptions = () => {
        return calNoOptionsDropDown.map((data, idx) => {
            return <LiComboList key={data} value={parseInt(data.calendarNo)}>{data.calendarDesc}</LiComboList>
        })
    }

    let weightComboOptions = () => {
        return weightDropDownOptions.map(data => {
            return <LiComboList key={data} value={data}>{mapEnumUNIT[data]}</LiComboList>
        })
    }

    let areaComboOptions = () => {
        return areaDropDownOptions.map(data => {
            // if (data === "Area_SquareMeter") {
            //     return <LiComboList key={"Area_SquareMeter"} value={"Area_SquareMeter"}><LiMultiterm textId="" textVal="sqm" /></LiComboList>
            // } else {
                return <LiComboList key={data} value={data}>{mapEnumUNIT[data]}</LiComboList>
            // }
        })
    }

    let handleComboChangeTime = (value) => {
        // let tempData = JSON.parse(JSON.stringify(machineData));
        let tempData = machineData
        tempData["downtimeThresholdAttribute"] = value[0]
        setMachineData(tempData)
        if(!props.filterStore.addNewMachineLine){
            handleSubmit()
        }
    }

    let handleComboCalNo = (value) => {
        // let tempData = JSON.parse(JSON.stringify(machineData));
        let tempData = machineData
        tempData["calNo"] = value[0]
        setMachineData(tempData)
        if(!props.filterStore.addNewMachineLine){
            handleSubmit()
        }
    }

    let handleComboChangeArea = (value) => {
        // let tempData = JSON.parse(JSON.stringify(machineData));
        let tempData = machineData
        tempData["areaThresholdAttribute"] = value[0];
        setMachineData(tempData)
        if(!props.filterStore.addNewMachineLine){
            handleSubmit()
        }
    }

    let handleComboChangeWeight = (value) => {
        // let tempData = JSON.parse(JSON.stringify(machineData));
        let tempData = machineData
        tempData["weightThresholdAttribute"] = value[0];
        setMachineData(tempData)
        if(!props.filterStore.addNewMachineLine){
            handleSubmit()
        }
    }

    // function getUniqueListBy(arr, key) {
    //     return [...new Map(arr.map(item => [item[key], item])).values()]
    // }

    let switchToMachineLine = (machine, type) => {
        if (type === "MACHINE_GROUP") {
            machine.machineId = machine.machineGroupId
            machine.machineName = machine.machineGroupName
            window.history.pushState("", "", `${window.location.origin}${window.location.pathname}?machineId=${machine?.machineId}`);
        }
        let tempArray = props.filterStore.recentList;
        tempArray.unshift(machine)
        if (tempArray.length > 5) {
            tempArray.length = 5
        }
        const recentSelected = [...new Set(tempArray)];
        // const filteredArray = getUniqueListBy(recentSelected, "machineId");
        props.setRecentList(recentSelected)
        sessionStorage.setItem("recentList", JSON.stringify(recentSelected));
        let currentSelectedMachine = {
            keyName: "selectedMachine",
            value: machine.machineId,
            name: machine.machineName
        }
        props.setAddNewMachineLine(false)
        props.setSelectedMachine(machine.machineId)
        OpenApiClient_ng_user_management_v1.getClient(siteName).PATCH_UserSettings_batch(callbackUserSettings, new OpenApiBody(OpenApiConst.MimeTypes.MIME_APPLICATION_JSON, [currentSelectedMachine]))

    }

    let callbackUserSettings = (responseData) => {
        if (responseData.state.response.status === 200) {

        }
        else {
            let errorData = conversionLib.convertToJSON(responseData?.state?.response?.data);
            LiStatusManager.showStatus(responseData.state.response.status, errorData.title);
        }
    }

    let machineDetails = () => {
        return (
            <div className='MachineLineManagementContainer'>
                <div className='MachineLineManagementImage'>
                    <div className='MachineLineManagementImageMain'>
                        {machineData.machineImage === undefined ?
                            <img width="200px" src={image} /> :
                            <img width="200px" src={"data:image/jpeg;base64," + machineData.machineImage} />
                        }
                    </div>
                    <input type="file" accept="image/*" name="image-upload" id="input" onChange={imageHandler} />
                    <span className="label">
                        <label className="image-upload" htmlFor="input">
                            <LiMultiterm textId="99030739" textVal="Change Picture" />
                        </label>
                    </span>
                </div>
                <div className='MachineLineManagementFieldsContainer'>
                    <div className='MachineLineManagementFields'>
                        <div > <LiMultiterm textId="99032721" textVal="Part of group/line" /> :</div>
                        <div>{machineData.machineGroupId !== undefined ? <span className='MachineLineClick' onClick={() => switchToMachineLine(machineData, "MACHINE_GROUP")}>{machineData.machineGroupName !== undefined ? machineData.machineGroupName : machineData.machineGroupId}</span> : <LiMultiterm textId="99016124" textVal="Unassigned" />}</div>
                    </div>
                    <div className='MachineLineManagementFields'>
                        <div><LiMultiterm textId="06008744" textVal="Serial Number" /> :</div>
                        <div>{machineData.machineId}</div>
                    </div>
                    <div className='MachineLineManagementFields'>
                        <div className='MachineLineManagementFieldsLabel'><LiMultiterm textId="06000333" textVal="Name" /> :</div>
                        <div>
                            <LiLineEdit liEditOnBlur={(e) => !props.filterStore.addNewMachineLine ? handleSubmit(e) : null} liEditName="machineName" liEditID="machineName" liEditValue={machineData.machineName} liEditClassName="dtMasterData_lineEdit" liEditOnChange={handleChange} liEditOnKeyUp={handleChange} />
                        </div>
                    </div>
                    <div className='MachineLineManagementFields'>
                        <div className='MachineLineManagementFieldsLabel'><LiMultiterm textId="99002931" textVal="Model" /> :</div>
                        <div>
                            <LiLineEdit liEditOnBlur={(e) => !props.filterStore.addNewMachineLine ? handleSubmit(e) : null} liEditID="machineModel" liEditName="machineModel" liEditValue={machineData.machineModel} liEditClassName="dtMasterData_lineEdit" liEditOnChange={handleChange} liEditOnKeyUp={handleChange} />
                        </div>
                    </div>
                    <div className='MachineLineManagementFields'>
                        <div className='MachineLineManagementFieldsLabel'><LiMultiterm textId="99003707" textVal="Calender" /> :</div>
                        <div className='MachineLineManagementFieldsCombo'>
                            {machineData.machineGroupId !== undefined ? <LiTooltip3 position="top-left" content={<LiMultiterm textId="" textVal={"From line : " + machineData.machineGroupName + " - " + machineData.machineGroupId} />}>
                                <span>
                                    <LiComboBox disabled id={"calNo"} onSelection={handleComboCalNo} defaultText={<LiMultiterm textId="99009084" textVal="Select" />} value={parseInt(machineData.calNo) === undefined ? [] : parseInt(machineData.calNo)} >
                                        {calNoOptions()}
                                    </LiComboBox>
                                </span>
                            </LiTooltip3> :
                                <LiComboBox id={"calNo"} onSelection={handleComboCalNo} defaultText={<LiMultiterm textId="99009084" textVal="Select" />} value={parseInt(machineData.calNo) === undefined ? [] : parseInt(machineData.calNo)} >
                                    {calNoOptions()}
                                </LiComboBox>}
                        </div>
                    </div>
                    <div className='MachineLineManagementFields'>
                        <div className='MachineLineManagementFieldsLabel'><LiMultiterm textId="99032722" textVal="Downtime threshold Limit value for downtime" /> :</div>
                        <div className='MachineLineManagementComboContainer'>
                            {machineData.machineGroupId !== undefined ? <LiTooltip3 position="top-left" content={<LiMultiterm textId="" textVal={"From line : " + machineData.machineGroupName + " - " + machineData.machineGroupId} />}>
                                <span>
                                    <LiLineEdit liEditOnBlur={(e) => !props.filterStore.addNewMachineLine ? handleSubmit(e) : null} liReadOnly={true} liEditID="downtimeThresholdDisabled" liEditName="downtimeThreshold" liEditClassName="dtMasterData_lineEdit" liEditValue={machineData.downtimeThreshold} liEditOnChange={handleChange} liEditOnKeyUp={handleChange} />
                                </span>
                            </LiTooltip3> :
                                <LiLineEdit liEditOnBlur={(e) => !props.filterStore.addNewMachineLine ? handleSubmit(e) : null} liEditID="downtimeThreshold" liEditName="downtimeThreshold" liEditClassName="dtMasterData_lineEdit" liEditValue={machineData.downtimeThreshold} liEditOnChange={handleChange} liEditOnKeyUp={handleChange} />}

                            {machineData.machineGroupId !== undefined ? <LiTooltip3 position="top-left" content={<LiMultiterm textId="" textVal={"From line : " + machineData.machineGroupName + " - " + machineData.machineGroupId} />}>
                                <span>
                                    <div className='MachineLineManagementCombo'>
                                        <LiComboBox disabled id={"downtimeThreshold"} onSelection={handleComboChangeTime} defaultText={<LiMultiterm textId="99009084" textVal="Select" />} value={machineData.downtimeThresholdAttribute === undefined ? [] : machineData.downtimeThresholdAttribute}>
                                            {timeComboOptions()}
                                        </LiComboBox>
                                    </div>
                                </span>
                            </LiTooltip3> :
                                <div className='MachineLineManagementCombo'>
                                    <LiComboBox id={"downtimeThreshold"} onSelection={handleComboChangeTime} defaultText={<LiMultiterm textId="99009084" textVal="Select" />} value={machineData.downtimeThresholdAttribute === undefined ? [] : machineData.downtimeThresholdAttribute}>
                                        {timeComboOptions()}
                                    </LiComboBox>
                                </div>}



                        </div>
                    </div>

                    <LiMultiterm textId="99034059" textVal="Production goal per hour" />

                    <div className='MachineLineManagementFields'>
                        <div className='MachineLineManagementFieldsLabel'><LiMultiterm textId="06003843" textVal="Quantity" /> :</div>
                        {machineData.machineGroupId !== undefined ? <LiTooltip3 position="top-left" content={<LiMultiterm textId="" textVal={"From line : " + machineData.machineGroupName + " - " + machineData.machineGroupId} />}>
                            <span>
                                <LiLineEdit liEditOnBlur={(e) => !props.filterStore.addNewMachineLine ? handleSubmit(e) : null} liReadOnly={true} liEditID="partThresholdDisabled" liEditName="partThreshold" liEditClassName="dtMasterData_lineEdit" liEditValue={machineData.partThreshold} liEditOnChange={(e) => handleChange(e, "partThreshold")} />
                            </span>
                        </LiTooltip3> :
                            <LiLineEdit liEditOnBlur={(e) => !props.filterStore.addNewMachineLine ? handleSubmit(e) : null} liEditID="partThreshold" liEditName="partThreshold" liEditClassName="dtMasterData_lineEdit" liEditValue={machineData.partThreshold} liEditOnChange={(e) => handleChange(e, "partThreshold")} />}
                    </div>
                    <div className='MachineLineManagementFields'>
                        <div className='MachineLineManagementFieldsLabel'><LiMultiterm textId="06003956" textVal="Area" /> :</div>
                        <div className='MachineLineManagementComboContainer'>
                            {machineData.machineGroupId !== undefined ? <LiTooltip3 position="top-left" content={<LiMultiterm textId="" textVal={"From line : " + machineData.machineGroupName + " - " + machineData.machineGroupId} />}>
                                <span>
                                    <LiLineEdit liEditOnBlur={(e) => !props.filterStore.addNewMachineLine ? handleSubmit(e) : null} liReadOnly={true} liEditID="areaThresholdDisabled" liEditName="areaThreshold" liEditClassName="dtMasterData_lineEdit" liEditValue={machineData.areaThreshold} liEditOnChange={handleChange} liEditOnKeyUp={handleChange} />
                                </span>
                            </LiTooltip3> :
                                <LiLineEdit liEditOnBlur={(e) => !props.filterStore.addNewMachineLine ? handleSubmit(e) : null} liEditID="areaThreshold" liEditName="areaThreshold" liEditClassName="dtMasterData_lineEdit" liEditValue={machineData.areaThreshold} liEditOnChange={handleChange} liEditOnKeyUp={handleChange} />}

                            {machineData.machineGroupId !== undefined ? <LiTooltip3 position="top-left" content={<LiMultiterm textId="" textVal={"From line : " + machineData.machineGroupName + " - " + machineData.machineGroupId} />}>
                                <span>
                                    <div className='MachineLineManagementCombo'>
                                        <LiComboBox disabled id={"areaThreshold"} onSelection={handleComboChangeArea} defaultText={<LiMultiterm textId="99009084" textVal="Select" />} value={machineData.areaThresholdAttribute === undefined ? [] : machineData.areaThresholdAttribute}>
                                            {areaComboOptions()}
                                        </LiComboBox>
                                    </div>
                                </span>
                            </LiTooltip3> :
                                <div className='MachineLineManagementCombo'>
                                    <LiComboBox id={"areaThreshold"} onSelection={handleComboChangeArea} defaultText={<LiMultiterm textId="99009084" textVal="Select" />} value={machineData.areaThresholdAttribute === undefined ? [] : machineData.areaThresholdAttribute}>
                                        {areaComboOptions()}
                                    </LiComboBox>
                                </div>}


                        </div>
                    </div>
                    <div className='MachineLineManagementFields'>
                        <div className='MachineLineManagementFieldsLabel'><LiMultiterm textId="06003997" textVal="Weight" /> :</div>
                        <div className='MachineLineManagementComboContainer'>
                            {machineData.machineGroupId !== undefined ? <LiTooltip3 position="top-left" content={<LiMultiterm textId="" textVal={"From line : " + machineData.machineGroupName + " - " + machineData.machineGroupId} />}>
                                <span>
                                    <LiLineEdit liEditOnBlur={(e) => !props.filterStore.addNewMachineLine ? handleSubmit(e) : null} liReadOnly={true} liEditID="weightThresholdDisabled" liEditName="weightThreshold" liEditClassName="dtMasterData_lineEdit" liEditValue={machineData.weightThreshold} liEditOnChange={handleChange} liEditOnKeyUp={handleChange} />
                                </span>
                            </LiTooltip3> :
                                <LiLineEdit liEditOnBlur={(e) => !props.filterStore.addNewMachineLine ? handleSubmit(e) : null} liEditID="weightThreshold" liEditName="weightThreshold" liEditClassName="dtMasterData_lineEdit" liEditValue={machineData.weightThreshold} liEditOnChange={handleChange} liEditOnKeyUp={handleChange} />}

                            {machineData.machineGroupId !== undefined ? <LiTooltip3 position="top-left" content={<LiMultiterm textId="" textVal={"From line : " + machineData.machineGroupName + " - " + machineData.machineGroupId} />}>
                                <span>
                                    <div className='MachineLineManagementCombo'>
                                        <LiComboBox disabled id={"weightThreshold"} onSelection={handleComboChangeWeight} defaultText={<LiMultiterm textId="99009084" textVal="Select" />} value={machineData.weightThresholdAttribute === undefined ? [] : machineData.weightThresholdAttribute}>
                                            {weightComboOptions()}
                                        </LiComboBox>
                                    </div>
                                </span>
                            </LiTooltip3> :
                                <div className='MachineLineManagementCombo'>
                                    <LiComboBox id={"weightThreshold"} onSelection={handleComboChangeWeight} defaultText={<LiMultiterm textId="99009084" textVal="Select" />} value={machineData.weightThresholdAttribute === undefined ? [] : machineData.weightThresholdAttribute}>
                                        {weightComboOptions()}
                                    </LiComboBox>
                                </div>}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    let machineGroup = () => {
        return (
            <div className='MachineLineGroupManagementContainer'>
                <div className='MachineLineGroupManagementImage'>
                    <div className='MachineLineManagementImageMain'>
                        {machineData.machineImage === undefined ?
                            <img width="200px" src={image} /> :
                            <img width="200px" src={"data:image/jpeg;base64," + machineData.machineImage} />
                        }
                    </div>
                    <input type="file" accept="image/*" name="image-upload" id="input" onChange={imageHandler} />
                    <span className="label">
                        <label className="image-upload" htmlFor="input">
                            <LiMultiterm textId="99030739" textVal="Change Picture" />
                        </label>
                    </span>
                </div>
                <div className='MachineLineGroupManagementFieldsContainer'>
                    <LiMultiterm textId="06000725" textVal="Configuration" />
                    <div className='MachineLineManagementFields'>
                        <div className='MachineLineManagementFieldsLabel'>Name <span className='MandatoryStar'>*</span> : </div>
                        <div>
                            <LiLineEdit liEditOnBlur={(e) => !props.filterStore.addNewMachineLine ? handleSubmit(e) : null} liEditName="name" liEditID="name" liEditValue={machineData.name} liEditClassName="dtMasterData_lineEdit" liEditOnChange={handleChange} liEditOnKeyUp={handleChange} />
                        </div>
                    </div>
                    <div className='MachineLineManagementFields'>
                        <div className='MachineLineManagementFieldsLabel'><LiMultiterm textId="99003707" textVal="Calender" /> :</div>
                        <div className='MachineLineManagementFieldsCombo'>
                            <LiComboBox id={"calNo"} onSelection={handleComboCalNo} defaultText={<LiMultiterm textId="99009084" textVal="Select" />} value={parseInt(machineData.calNo) === undefined ? [] : parseInt(machineData.calNo)}>
                                {calNoOptions()}
                            </LiComboBox>
                        </div>
                    </div>
                    <div className='MachineLineManagementFields'>
                        <div className='MachineLineManagementFieldsLabel'><LiMultiterm textId="99032722" textVal="Downtime threshold Limit value for downtime" /> :</div>
                        <div className='MachineLineManagementComboContainer'>
                            <LiLineEdit liEditOnBlur={(e) => !props.filterStore.addNewMachineLine ? handleSubmit(e) : null} liEditName="downtimeThreshold" liEditID="downtimeThreshold" liEditClassName="dtMasterData_lineEdit" liEditValue={machineData.downtimeThreshold} liEditOnChange={handleChange} liEditOnKeyUp={handleChange} />
                            <div className='MachineLineManagementCombo'>
                                <LiComboBox id={"downtimeThreshold"} onSelection={handleComboChangeTime} defaultText={<LiMultiterm textId="99009084" textVal="Select" />} value={machineData.downtimeThresholdAttribute === undefined ? [] : machineData.downtimeThresholdAttribute}>
                                    {timeComboOptions()}
                                </LiComboBox>
                            </div>

                        </div>
                    </div>

                    <LiMultiterm textId="99034059" textVal="Production goal per hour" />

                    <div className='MachineLineManagementFields'>
                        <div className='MachineLineManagementFieldsLabel'><LiMultiterm textId="06003843" textVal="Quantity" /> :</div>
                        <LiLineEdit liEditOnBlur={(e) => !props.filterStore.addNewMachineLine ? handleSubmit(e) : null} liEditID="partThreshold" liEditName="partThreshold" liEditClassName="dtMasterData_lineEdit" liEditValue={machineData.partThreshold} liEditOnChange={(e) => handleChange(e, "partThreshold")} />
                    </div>
                    <div className='MachineLineManagementFields'>
                        <div className='MachineLineManagementFieldsLabel'><LiMultiterm textId="06003956" textVal="Area" /> :</div>
                        <div className='MachineLineManagementComboContainer'>
                            <LiLineEdit liEditOnBlur={(e) => !props.filterStore.addNewMachineLine ? handleSubmit(e) : null} liEditID="areaThreshold" liEditName="areaThreshold" liEditClassName="dtMasterData_lineEdit" liEditValue={machineData.areaThreshold} liEditOnChange={handleChange} liEditOnKeyUp={handleChange} />
                            <div className='MachineLineManagementCombo'>
                                <LiComboBox id={"areaThreshold"} onSelection={handleComboChangeArea} defaultText={<LiMultiterm textId="99009084" textVal="Select" />} value={machineData.areaThresholdAttribute === undefined ? [] : machineData.areaThresholdAttribute}>
                                    {areaComboOptions()}
                                </LiComboBox>
                            </div>
                        </div>
                    </div>
                    <div className='MachineLineManagementFields'>
                        <div className='MachineLineManagementFieldsLabel'><LiMultiterm textId="06003997" textVal="Weight" /> :</div>
                        <div className='MachineLineManagementComboContainer'>
                            <LiLineEdit liEditOnBlur={(e) => !props.filterStore.addNewMachineLine ? handleSubmit(e) : null} liEditID="weightThreshold" liEditName="weightThreshold" liEditClassName="dtMasterData_lineEdit" liEditValue={machineData.weightThreshold} liEditOnChange={handleChange} liEditOnKeyUp={handleChange} />
                            <div className='MachineLineManagementCombo'>
                                <LiComboBox id={"weightThreshold"} onSelection={handleComboChangeWeight} defaultText={<LiMultiterm textId="99009084" textVal="Select" />} value={machineData.weightThresholdAttribute === undefined ? [] : machineData.weightThresholdAttribute}>
                                    {weightComboOptions()}
                                </LiComboBox>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='MachineLineMachinesContainerMain'>
                    <div className='MachineLineMachinesContainer'>
                        <span><LiMultiterm textId="99000849" textVal="Machines" /></span>
                        {selectedMachines?.length === 0 ? "" :
                            <div className='MachineLineMachinesButton'>
                                <button onClick={showMachineList}>+</button>
                                <div className='MachineLineMachinesButtonLineEnd'></div>
                            </div>}
                        {selectedMachines?.length > 0 ? selectedMachines.map((machine, idx) => {
                            return (
                                <div>
                                    <div key={idx} className='MachineLineMachines'>
                                        {machine.machineImage !== undefined ?
                                            <img width="50px" src={machine.machineImage} />

                                            : <img width="50px" src={machineImage} />}
                                        <div>
                                            <span>{machine.machineId}</span>
                                            <span>Applicator Machine</span>
                                        </div>
                                        <div className='MachineLineMachinesDeleteButton'>
                                            <button onClick={() => handleDelete(machine)}>x</button>
                                        </div>

                                    </div>
                                    <div className='MachineLineMachinesButton'>
                                        <div className='MachineLineMachinesButtonLine'></div>
                                        <button onClick={() => showMachineList(machine, idx)}>+</button>
                                        {selectedMachines.length !== (idx + 1) ?
                                            <div className='MachineLineMachinesButtonLineEnd'></div>
                                            :
                                            ""}

                                    </div>
                                </div>
                            )
                        })
                            :
                            <div className='MachineLineMachinesButtonAlone'>
                                <button onClick={showMachineList}>+</button>
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }

    let returnMachineAndGroup = () => {
        if (showMachine) {
            return machineDetails()
        } else if (showMachineGroup) {
            return machineGroup()
        }
    }

    const themeName = conversionLib.getLocalStorageValue("core.app.theme") !== null ? (conversionLib.getLocalStorageValue("core.app.theme")) : "LightTheme";

    let loadMachineList = () => {
        return (
            <div className='MachineGroupContainer'>
                {machineList.length > 0 && machineList.map((machine, index) => {
                    return <div key={"Machine" + index} className='MachineGroup' onClick={() => handleAddMachineToGroup(machine)} >{machine.machineId}</div>
                })}
            </div>
        )
    }

    let handleAddMachineToGroup = (machine) => {
        let machines = selectedMachines;
        machines.splice(newMachineIndex + 1, 0, machine)
        setSelectedDate(machines)
        if(!props.filterStore.addNewMachineLine){
            handleSubmit()
        }
        setMachinesToSelectPopUp(false)
    }

    let hideWarning = () => {
        setShowWarning(false)
    }

    return (
        <div className='MachineStageChangesContainer'>
            {redirect ? <Redirect to="machine_and_line_management?CreateNewLine=true" /> : ""}
            <div className={`OEE_Breadcrumbs ${props.hide ? "BreadCrumbHeaderHide" : ""}`}>
                <LiBreadCrumbs breadcrumbsdataObj={oeedashboardObj} />
            </div>
            <div className='OEEDashboard_layoutSidebarContainer'>
                <FilterpanelXS showShifts={false} showRefresh={false} loader={props.loader} setHide={props.setHide} />
                <div id="OEEDashboard_Layout_Div" className="OEEDashboard_Layout_Div">
                {
                    showWarning ?
                        <LiReactDialog
                            enableStoring="warning"
                            title={<LiMultiterm textId="" textVal="Warning" />}
                            actionsContainerStyle={{ backgroundColor: '#ffffff', padding: '8px' }}
                            onClose={showMachineList}
                            hasCloseIcon={true} isResizable={true} isDraggable={true} modal={true}
                            width={500} height={200} buttons={
                                [
                                    {
                                        text: <LiMultiterm textId="99000240" textVal="Close" />,
                                        onClick: () => hideWarning(),
                                        className: "LiGeneral_raisedButton"
                                    }
                                ]
                            }
                        >
                            <div className='PleaseSelectAMachineMessage'>
                                <LiMultiterm textId="99033146" textVal="Name Or Machine Members Missing" />
                            </div>
                        </LiReactDialog>
                        : ""
                }
                    {props.filterStore.addNewMachineLine ? machineGroup() : returnMachineAndGroup()
                    }
                    <div className='MachineStageChangesContainerSubmitButton'>
                        {
                            props.filterStore.addNewMachineLine ? <LiButton
                                buttonLabel={<LiMultiterm textId="06001696" textVal="Add" />}
                                buttonOnClickHandler={() => handleSubmit("COMBO")} /> : ""
                        }

                        {showMachineGroup && !props.filterStore.addNewMachineLine ?
                            <LiButton
                                buttonLabel={<LiMultiterm textId="99003381" textVal="Delete" />}
                                buttonOnClickHandler={handleDeleteMachineGroupPopUp}
                                buttonDisabled={props.filterStore.addNewMachineLine} />
                            : ""}

                        {props.filterStore.addNewMachineLine ?
                            <LiButton
                                buttonLabel={<LiMultiterm textId="99002343" textVal="Cancel" />}
                                buttonOnClickHandler={handleCancel} />
                            : ""}
                    </div>
                </div>

                {
                    machineToSelectPopUp ?
                        <LiReactDialog
                            enableStoring="delivery_trackingsortTruck"
                            title={<LiMultiterm textId="99019011" textVal="Select Machine" />}
                            actionsContainerStyle={{ backgroundColor: '#ffffff', padding: '8px' }}
                            onClose={showMachineList}
                            hasCloseIcon={true} isResizable={true} isDraggable={true} modal={true}
                            width={900} height={600} buttons={
                                [
                                    {
                                        text: <LiMultiterm textId="99000240" textVal="Close" />,
                                        onClick: () => showMachineList(),
                                        className: "LiGeneral_raisedButton"
                                    }
                                ]
                            }
                        >
                            {loadMachineList()}
                        </LiReactDialog>
                        : ""
                }
                {
                    showDeletePopUp ?
                        <LiReactDialog
                            enableStoring="DeletePopUPMachine"
                            title={<LiMultiterm textId="99003381" textVal="Delete" />}
                            actionsContainerStyle={{ backgroundColor: '#ffffff', padding: '8px' }}
                            onClose={handleDeleteMachineGroupPopUp}
                            hasCloseIcon={true} isResizable={true} isDraggable={true} modal={true}
                            width={300} height={300} buttons={
                                [
                                    {
                                        text: <LiMultiterm textId="99001613" textVal="Yes" />,
                                        onClick: () => handelDeleteCall(),
                                        className: "LiGeneral_raisedButton"
                                    },
                                    {
                                        text: <LiMultiterm textId="99000054" textVal="No" />,
                                        onClick: () => handleDeleteMachineGroupPopUp(),
                                        className: "LiGeneral_raisedButton"
                                    }
                                ]
                            }
                        >
                            <LiMultiterm textId="99033147" textVal="Do you want to delete the selected line?" /> <b>{props.filterStore.selectedMachine} </b>?
                        </LiReactDialog>
                        : ""
                }
                {
                    showAddMachineMemberPopUp ?
                        <LiReactDialog
                            enableStoring="DeletePopUPMachine"
                            title={<LiMultiterm textId="99033145" textVal="Name and Machine Required" />}
                            actionsContainerStyle={{ backgroundColor: '#ffffff', padding: '8px' }}
                            onClose={closeMachineMemberPopUp}
                            hasCloseIcon={true} isResizable={true} isDraggable={true} modal={true}
                            width={300} height={100} buttons={
                                [
                                    {
                                        text: <LiMultiterm textId="01001439" textVal="Close" />,
                                        onClick: () => closeMachineMemberPopUp(),
                                        className: "LiGeneral_raisedButton"
                                    }
                                ]
                            }
                        ><div className='PleaseSelectAMachineMessage'>
                                <LiMultiterm textId="99033146" textVal="Name Or Machine Members Missing" />
                            </div>
                        </LiReactDialog>
                        : ""
                }
                <FilterPanel showShifts={false} showRefresh={false} loader={props.loader} setHide={props.setHide} />
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        filterStore: state.filterStore
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setSelectedDate,
        setAddNewMachineLine,
        setSelectedMachine,
        setMachineGroupList,
        setMachineList,
        setRecentList,
        setShifts: FilterActions.setSelectedShifts,
        setShiftsList : FilterActions.setShiftsList
    }, dispatch);
}
    export default connect(mapStateToProps, mapDispatchToProps)(MachineAndLineManagement);