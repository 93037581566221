import React, { useState } from 'react'
import CalendarWrapper from '../filtercomponents/calendar'
import Shifts from '../filtercomponents/shifts'
import MachineSelector from '../filtercomponents/machineselector'
import * as conversionLib from 'lisecutilityfunctions/lib/commonfunction';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { setSelectedDate } from '../actions/filteractions'
import '../css/oeedashboard.css'
import '../filtercomponents/filter.css'
import AutoRefresh from '../filtercomponents/autorefresh';

function FilterPanel(props) {

  const themeName = conversionLib.getLocalStorageValue("core.app.theme") !== null ? (conversionLib.getLocalStorageValue("core.app.theme")) : "LightTheme";


  const selectedDate = (value) => {
    props.setSelectedDate(value)
    sessionStorage.setItem("selectedDate", value);
  }

  return (

    <div id="FilterPanel" className={themeName + " OEE_sideNav"} >
      {props.showCalender || props.showCalender === undefined ?
        <CalendarWrapper selectedDate={selectedDate} />
        : ""}
      {props.showShifts || props.showShifts === undefined ? <Shifts />
        : ""}
      {props.showMachines || props.showMachines === undefined ?
        <MachineSelector loader={props.loader} setHide={props.setHide} />
        : ""}
       {props.showRefresh || props.showRefresh === undefined ?
        <AutoRefresh isMobile={false}/> : "" }
    </div>




  )
}

function mapStateToProps(state) {
  return {
    filterStore: state.filterStore
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    setSelectedDate
  }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(FilterPanel);
