import React from 'react';
import Calendar from 'devextreme-react/calendar';
import * as conversionLib from 'lisecutilityfunctions/lib/commonfunction';
import { initiateDevExpressLang } from '../../oeedashboard/utility/utilitydevexpress';

const zoomLevels = ['month', 'year', 'decade', 'century'];

function getWeekNumber(date) {
    const firstDayOfWeek = 0; // 0 for Sunday, 1 for Monday
    const dateObj = new Date(date);
    
    // Set the date to the first day of the year
    const firstDayOfYear = new Date(dateObj.getFullYear(), 0, 1);
    
    // Adjust the first day of the week if necessary
    const dayOfWeek = firstDayOfYear.getDay();
    const daysToSubtract = (dayOfWeek < firstDayOfWeek) ? 7 - (firstDayOfWeek - dayOfWeek) : (dayOfWeek - firstDayOfWeek);
    firstDayOfYear.setDate(firstDayOfYear.getDate() - daysToSubtract);
    
    // Calculate the number of weeks
    const diffInTime = dateObj - firstDayOfYear;
    const diffInDays = Math.floor(diffInTime / (1000 * 3600 * 24));
    const weekOfYear = Math.floor(diffInDays / 7) + 1;
    
    return weekOfYear;
}  

/** 
* @description Generate the week days for calendar 
* @description Function is called when on content ready and when there is a change in month/option cahnged
* @description Gets all the header items and prepend 'W' to the first column and gets all the table rows and prepend the week number to the first column 
* @returns {void}
*/

function generateWeekDays() {

    const currentViewElement = document.getElementById("FilterPanel")
    const getHeaderTr = currentViewElement.querySelectorAll("thead > tr")

    for (let index = 0; index < getHeaderTr.length; index++) {
        const element = getHeaderTr[index];
        if (element.childNodes.length > 0 && element.childNodes.length < 8) {
            let header = document.createElement("th")
            header.innerHTML = "#"
            
            header.classList.add("weeknumbercells")
            
            // header.style.textAlign = "left"
            header.addEventListener("click", (ev) => {
                ev.stopPropagation();
                ev.preventDefault();
            })
            element.prepend(header)
        }

    }

    const getWeekDayCells = currentViewElement.querySelectorAll(".weekdaycells")
    for (let index = 0; index < getWeekDayCells.length; index++) {
        const element = getWeekDayCells[index];
        element.remove()

    }


    const getBodyTr = currentViewElement.querySelectorAll("tbody > tr")
    for (let index = 0; index < getBodyTr.length; index++) {
        const element = getBodyTr[index];
        const date = element.childNodes[0].getAttribute("data-value")
        if (date) {
            let column = document.createElement("td")
            column.classList.add("weekdaycells")
            column.style.fontSize = "12px"
            column.style.paddingTop = "5px"
            column.style.color = "rgba(125,125,125,.87)"
            column.innerHTML = getWeekNumber(new Date(date))
            column.addEventListener("click", (ev) => {
                ev.stopPropagation();
                ev.preventDefault();
            })
            element.prepend(column)
        }
    }
}

function onContentReady(e) {
    if (e.component.option("zoomLevel") !== "month")
        return;

    console.warn("inside")

    setTimeout(function () {
        generateWeekDays()
    }, 0);
}
class CalendarWrapper extends React.Component {
    constructor() {
        super();
        const language = conversionLib.getLocalStorageValue("core.app.language.id3")
        initiateDevExpressLang(language);
        this.state = {
            minDateValue: null,
            maxDateValue: null,
            disabledDates: null,
            firstDay: conversionLib.getLocalStorageValue("weekDayStart") !== null ? parseInt((conversionLib.getLocalStorageValue("weekDayStart"))) : 0,
            currentValue: sessionStorage.getItem("selectedDate") === null ? new Date() : sessionStorage.getItem("selectedDate"),
            cellTemplate: null,
            disabled: false,
            zoomLevel: 'month',
            weekCount: 0,
            themeName: conversionLib.getLocalStorageValue("core.app.theme") !== null ? (conversionLib.getLocalStorageValue("core.app.theme")) : "LightTheme"
        };
        this.onCurrentValueChanged = this.onCurrentValueChanged.bind(this);
        this.onDisabledChanged = this.onDisabledChanged.bind(this);
        this.onZoomLevelValueChanged = this.onZoomLevelValueChanged.bind(this);
        this.onZoomLevelChanged = this.onZoomLevelChanged.bind(this);
        this.setMinDate = this.setMinDate.bind(this);
        this.setMaxDate = this.setMaxDate.bind(this);
        this.disableWeekend = this.disableWeekend.bind(this);
        this.setFirstDay = this.setFirstDay.bind(this);
        this.useCellTemplate = this.useCellTemplate.bind(this);
        this.onOptionChanged = this.onOptionChanged.bind(this);
        this.cellTemplatefn = this.cellTemplatefn.bind(this)
    }

    render() {
        const {
            currentValue,
            minDateValue,
            maxDateValue,
            disabledDates,
            firstDay,
            disabled,
            zoomLevel,
        } = this.state;
        return (
            <div id="calendar-demo">
                <div className="widget-container">
                    <Calendar
                        id="calendar-container"
                        width={"100%"}
                        height={330}
                        value={currentValue}
                        onValueChanged={this.onCurrentValueChanged}
                        onZoomLevelChanged={this.onZoomLevelChanged}
                        onOptionChanged={this.onOptionChanged}
                        min={minDateValue}
                        max={maxDateValue}
                        disabledDates={disabledDates}
                        firstDayOfWeek={firstDay}
                        disabled={disabled}
                        // zoomLevel={zoomLevel}
                        cellRender={this.cellTemplatefn}
                        onContentReady={onContentReady}
                        style={{ backgroundColor: this.state.themeName === "LightTheme" ? "#F1F2F3" : "var(--darktheme-black-variant2)" }}
                    />
                </div>
            </div>
        );
    }

    cellTemplatefn(cell, index) {
        // if (cell.view === "month" && index === 0) {
        //     const getBodyTr = document.getElementById("calendar-container").querySelectorAll("tbody > tr")[count]
        //     console.log(cell, "cellcell")
        //     if (getBodyTr) {
        //         let column = document.createElement("td")
        //         column.classList.add("weekdaycells")
        //         column.innerHTML = getWeekNumber(cell.date)
        //         column.addEventListener("click", (ev) => {
        //             ev.stopPropagation();
        //             ev.preventDefault();
        //         })
        //         getBodyTr.prepend(column)
        //         count++
        //     }
        // }
        return <span>{cell.text}</span>
    }

    onOptionChanged(e) {
        if (e.component.option("zoomLevel") !== "month")
            return;
        if (e.name === "currentDate" || e.name === "isActive") {
            generateWeekDays()
        }
    }

    onCurrentValueChanged(e) {
        this.setState({
            currentValue: e.value,
        });
        this.props.selectedDate(e.value)
        this.props.onDateSelected && this.props.onDateSelected()
    }

    onDisabledChanged(e) {
        this.setState({
            disabled: e.value,
        });
    }

    onZoomLevelValueChanged(e) {
        this.setState({
            zoomLevel: e.value,
        });
    }

    onZoomLevelChanged(e) {
        if (e.name === 'zoomLevel') {
            this.setState({
                zoomLevel: e.value,
            });
        }
    }

    setMinDate(e) {
        this.setState({
            minDateValue: e.value ? new Date((new Date()).getTime() - 1000 * 60 * 60 * 24 * 3) : null,
        });
    }

    setMaxDate(e) {
        this.setState({
            maxDateValue: e.value ? new Date((new Date()).getTime() + 1000 * 60 * 60 * 24 * 3) : null,
        });
    }

    disableWeekend(e) {
        this.setState({
            disabledDates: e.value ? (data) => data.view === 'month' && isWeekend(data.date) : null,
        });
    }

    setFirstDay(e) {
        this.setState({
            firstDay: e.value ? 1 : 0,
        });
    }

    useCellTemplate(e) {
        this.setState({
            cellTemplate: e.value ? CustomCell : null,
        });
    }
}

function CustomCell(cell) {
    return (
        <span className={getCellCssClass(cell.date)}>
            {cell.text}
        </span>
    );
}

function getCellCssClass(date) {
    let cssClass = '';
    const holydays = [[1, 0], [4, 6], [25, 11]];

    if (isWeekend(date)) { cssClass = 'weekend'; }

    holydays.forEach((item) => {
        if (date.getDate() === item[0] && date.getMonth() === item[1]) {
            cssClass = 'holyday';
        }
    });

    return cssClass;
}

function isWeekend(date) {
    const day = date.getDay();
    return day === 0 || day === 6;
}

export default CalendarWrapper;