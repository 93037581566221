import React, { useEffect, useState } from 'react'
import * as conversionLib from 'lisecutilityfunctions/lib/commonfunction';
import { LiTable } from 'lisecdependantcomp';
import { LiBreadCrumbs, LiReactDialog, LiStatusManager } from 'liseccomponents';
import breadcrumbsimg from '../images/dashboard.svg'
import { OpenApiClient_analytics_v1, getSiteName } from 'lisecutilityfunctions/lib/openapi';
import useWindowSize from '../hooks/useWindowSize';
import FilterPanel from '../filters/filterpanel';
import FilterpanelXS from '../filters/filterpanelsmalldevice';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { setSelectedDate } from '../actions/filteractions'
import { EventWidgetProp, formatDate } from '../utility/const';
import { DashBoardWidget } from "dashboard";
import { getMultitermTexts, LiMultiterm } from 'lisecutilityfunctions/lib/utilitylib';
import dateformatter, { i18n } from "dateformat";
import "./css/machinesandlines.css"
import "./css/eventlog.css"

export const tableConfiguration = {
    "getRowDataConfiguration": {
        "enableRowData": false
    },
    "linkConfiguration": {
        "enableLink": false
    },
    "paginationConfiguration": {
        "enablePaging": true,
        "pageSize": conversionLib.getLocalStorageValue("table.pagesize.summary_data") !== null ? conversionLib.getLocalStorageValue("table.pagesize.summary_data") : 10,
        "pageSizeCombo": [5, 10, 15, 20]
    },
    "sortingConfiguration": {
        "enableSorting": false,
        "sortingColumns": {},
        "defaultSortingColumn": "full_name",
        "defaultSortingOrder": "ASC"
    },
    "actionConfiguration": {
        "showAction": true,
        "actionItems": {
            "View": ['99000829', "View"],
            "Edit": ['6001743', "Edit"]
        },
        "receiverFunction": "getDataFromTable"
    },
    "dataTypeConfiguration": {
        "routes": "text",
        "deliveries": "text",
        "containers": "text",
        "pieces": "text",
        "weight": "text",
    },
    "multiSelectable": {
        "enabled": false,
        "showCheckboxes": false
    },
    "enableTrigger": false,
    "showHeaderSettings": false
};

let monthNamesMultiTermArr = [     //monthNames multiterms
    99004400, 99004401, 99003720, 99004402, 99004403, 99003721, 99004404, 99003903, 99013085, 99009404, 99004405, 99006224, 99004393, 99004394, 99003719, 99015764, 99004403, 99003715, 99012503, 99004395, 99003716, 99004396, 99003717, 99009316,
]

let siteName = getSiteName();
let refreshInternal = null;

const ShowDialog = ({setOpenPopUp}) => {
    return (
        <LiReactDialog
            onClose={() => setOpenPopUp(false)}
            title={<LiMultiterm textId="99003007" textVal="Event log" />}
            hasCloseIcon={true}
            isResizable={false}
            isDraggable={false}
            modal={true}
            buttons={
                [
                    {
                        text: <LiMultiterm textId="99000240" textVal="Close" />,
                        onClick: () => setOpenPopUp(false),
                        className: "LiGeneral_raisedButton"
                    }
                ]
            }
        >
            <div style={{textAlign: 'center', marginTop: '120px'}}>
                <LiMultiterm textId="99034156" textVal="Event log not available for lines"></LiMultiterm>
            </div>
        </LiReactDialog>
    )
}

function MachineStageChanges(props) {
    const [tableData, setTableData] = useState({})
    const [chartData, setChartData] = useState(null)
    const [openPopUp, setOpenPopUp] = useState(false)

    useEffect(() => {
        {
            if(isNaN(parseInt(props.filterStore.selectedMachine)) == false) {
                setOpenPopUp(true)
                setTableData({})
            }
        }
        getData()
    }, [props.filterStore.selectedMachine, props.filterStore.selectedDate, props.filterStore.selectedShifts])    

    let getData = () => {
        if (props.filterStore.selectedMachine !== null) {
            props.loader.showLoader({ "GET_MachineAvailability_siteId_machineId": true })
            OpenApiClient_analytics_v1.getClient(siteName).GET_MachineAvailability_siteId_machineId(callBackForTableData, 1, props.filterStore.selectedMachine, props.filterStore.selectedDate, props.filterStore.selectedShifts.length > 0 ? props.filterStore.selectedShifts : null)
        }
        setChartData(null)
        setTimeout(() => {
            setChart()
        }, 200)
    }

    useEffect(() => {
        if (props.filterStore.autoRefresh) {
            autoRefreshData()
        }

        return () => {
            clearInterval(refreshInternal);
        }
    }, [props.filterStore.autoRefresh])

    const autoRefreshData = () => {
        clearInterval(refreshInternal);
        refreshInternal = setInterval(() => {
            setTableData({})
            getData()
        }, props.filterStore.autoRefresh * 60 * 1000);
    }

    let configureMultiTermForMonths = async () => {
        let monthNameResponseData = await getMultitermTexts(monthNamesMultiTermArr);
        if (monthNameResponseData.state.response !== undefined && monthNameResponseData.state.response.data !== undefined && monthNameResponseData.state.response.status === 200) {
            var jsonData = conversionLib.convertToJSON(monthNameResponseData.state.response.data);
            var monthNamesTextsArr = [];
            var convertedmonthNamesTextsArr = [];
            monthNamesMultiTermArr.map((eachTextNo, id) => {
                jsonData.map((eachTextVal, idx) => {
                    if (eachTextVal.number == eachTextNo) {
                        monthNamesTextsArr.push(eachTextVal.text)
                    }
                })
            });
        }
        for (let i = 0; i <= monthNamesTextsArr.length; i++) {
            if (monthNamesTextsArr[i] !== monthNamesTextsArr[i + 1]) {
                convertedmonthNamesTextsArr.push(monthNamesTextsArr[i])
            }
        }
        i18n.monthNames = convertedmonthNamesTextsArr
    }

    useEffect(()=> {
        configureMultiTermForMonths();
    },[])

    // Setting the data for the gantt chart and adding settings to it
    let setChart = () => {
        if (props.filterStore.selectedShifts !== null && props.filterStore.selectedDate !== null && props.filterStore.selectedMachine !== null) {
            let shiftSetting = ""
            if (props.filterStore.selectedShifts.length > 0) {
                shiftSetting = props.filterStore.selectedShifts
            }
            let dataSetting = {}
            if (isNaN(parseInt(props.filterStore.selectedMachine))) {
                dataSetting = {
                    "Date": props.filterStore.selectedDate,
                    "Machine": [props.filterStore.selectedMachine],
                    "Shift": shiftSetting
                }
            } else {
                dataSetting = {
                    "Date": props.filterStore.selectedDate,
                    "MachineGroupId": [props.filterStore.selectedMachine],
                    "Shift": shiftSetting
                }
            }


            EventWidgetProp.dataSettingsobject = dataSetting
            setChartData(EventWidgetProp)
        }
    }

    const oeedashboardObj = {
        "OEEDashBoard": {
            "label": <LiMultiterm textId="99003007" textVal="Event Log" />,
            "path": "#"
        }
    }

    let dateFormat = conversionLib.getLocalStorageValue("core.app.dateformat");
    let timeFormat = conversionLib.getLocalStorageValue("core.app.timeformat");

    let userId = conversionLib.getLocalStorageValue("user.id");
    let userSettings = JSON.parse(conversionLib.getLocalStorageValue(`config.userSettings.${userId}`))

    userSettings && userSettings.forEach(data => {
        if (data.keyName === "core.app.dateformat") {
            dateFormat = data?.value
        }
        if (data.keyName === "core.app.timeformat") {
            timeFormat = data?.value
        }
    })

    let callBackForTableData = (responseData) => {
        if (responseData.state.response.status === 200) {
            let textsNumbers = [];
            let jsonData = conversionLib.convertToJSON(responseData.state.response.data);
            if (jsonData.length > 0) {
                //Changing Date Format
                jsonData.map((data) => {
                    let startDate = data.fromDateTime.split("T")[0];
                    let startTime = data.fromDateTime.split("T")[1].substring(0, 8);
                    let endDate = data.toDateTime.split("T")[0];
                    let endTime = data.toDateTime.split("T")[1].substring(0, 8);
                    data.fromDateTime = startDate + " " + startTime
                    data.toDateTime = endDate + " " + endTime
                    data.fromDateTime = dateformatter(data.fromDateTime, dateFormat + " " + timeFormat)
                    data.toDateTime = dateformatter(data.toDateTime, dateFormat + " " + timeFormat)
                    textsNumbers.push(data.messageTextNo)
                    textsNumbers.push(data.machineStatusTextNo)
                    textsNumbers.push(data.reasonTextNo)
                })
                handleTexts(jsonData, textsNumbers)
            }
        }
        else if (responseData.state.response.status === 204) {
            setTableData({})
        }
        else {
            LiStatusManager.showStatus(responseData.state.response.status, responseData.detail);
        }
        props.loader.showLoader({ "GET_MachineAvailability_siteId_machineId": false })
    }

    let handleTexts = async (jsonData, textsNumbers) => {
        let obj = {};
        let tableDataObj = {};
        const finalTextArray = textsNumbers.filter(element => element !== undefined);
        let responseData = await getMultitermTexts(finalTextArray);
        if (responseData?.state?.response !== undefined && responseData?.state?.response?.status === 200) {
            setTableData({})
            var newjsonData = conversionLib.convertToJSON(responseData.state.response.data);
            for (let i = 0; i < newjsonData.length; i = i + 1) {
                if (obj && newjsonData && newjsonData[i]) {
                    obj[newjsonData[i].number] = newjsonData[i].text
                }
            }
            //Replacing Multiterm Numbers With Text
            jsonData.map(data => {
                if(data.messageTextNo) {
                    data.mainAlarmId = obj[data.messageTextNo]
                }
                if(data.machineStatusTextNo) {
                    data.machineStatus = obj[data.machineStatusTextNo]
                }
                if(data.reasonTextNo) {
                    data.reason = obj[data.reasonTextNo]
                }
            })
            Object.assign(tableDataObj, jsonData);
            setTableData(tableDataObj);
        } else {
            LiStatusManager.showStatus(responseData?.state?.response.status, responseData?.detail);
        }
    }

    const HeaderArr = {
        "fromDateTime": ['99000638', 'Start'],
        "toDateTime": ['01002574', 'End'],
        "machineId": ['04000132', 'Machine'],
        "machineStatus": ['03000144', 'Status'],
        "reason": ['09000830', 'Reason'],
        "mainAlarmId": ['99032166', 'Associated Alarm']
        // "id": ['', 'ID'],
    };

    const themeName = conversionLib.getLocalStorageValue("core.app.theme") !== null ? (conversionLib.getLocalStorageValue("core.app.theme")) : "LightTheme";

    let handleData = (selectedRows, rowID, rowData) => {
        // this.setState({ selectedRowData: selectedRows })
        console.log(selectedRows, rowID, rowData);
    }

    return (
        <div className='MachineStageChangesContainer'>
            {openPopUp && <ShowDialog setOpenPopUp={setOpenPopUp}/>}
            <div className={`OEE_Breadcrumbs ${props.hide ? "BreadCrumbHeaderHide" : ""}`}>
                <LiBreadCrumbs breadcrumbsdataObj={oeedashboardObj} />
            </div>
            <div className="OEEDashboard_layoutSidebarContainer">
                <FilterpanelXS showShifts={false} loader={props.loader} setHide={props.setHide} />
                <div id="OEEDashboard_Layout_Div" className="OEEDashboard_Layout_Div">
                    <div className="oee_dashboard_widgets Machine_State_Changes_oee_dashboard_widgets">
                        {
                            !chartData ?
                                null
                                :
                                <DashBoardWidget manifestation={1} widgetProperties={chartData} breadCrumbs={false} oeeDashboard={true} />
                        }
                    </div>
                    <div className='MachineStageChangesTableContainer'>
                        <LiTable tableId="summary_data" colHeaderArr={HeaderArr} rowArr={tableData} config={tableConfiguration} subTable={false} enableColSearch={false} handleData={handleData} />
                    </div>
                </div>
                <FilterPanel loader={props.loader} setHide={props.setHide}/>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        filterStore: state.filterStore
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setSelectedDate,
    }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(MachineStageChanges);