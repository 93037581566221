import React, { useState } from 'react'
import CalendarWrapper from '../filtercomponents/calendar'
import Shifts from '../filtercomponents/shifts'
import MachineSelector from '../filtercomponents/machineselector'
import * as conversionLib from 'lisecutilityfunctions/lib/commonfunction';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { setSelectedDate } from '../actions/filteractions'
import '../css/oeedashboard.css'
import '../filtercomponents/filter.css'

import DatePicker from '../images/datepicker.png'
import MachineSelection from '../images/machineselection.png'
import Shift from '../images/shift.png'
import Refresh from '../images/refresh.png'
import Autorefresh from '../filtercomponents/autorefresh';

function FilterPanelXS(props) {

    const themeName = conversionLib.getLocalStorageValue("core.app.theme") !== null ? (conversionLib.getLocalStorageValue("core.app.theme")) : "LightTheme";

    const [currentOpenedFilter, setcurrentOpenedFilter] = useState(null)

    const selectedDate = (value) => {
        props.setSelectedDate(value)
    }

    const closeFilter = () => {
        document.getElementById("myNav").style.width = "0%";
    }

    const openFilter = (type) => {
        document.getElementById("myNav").style.width = "100%";
        setcurrentOpenedFilter(type)
    }

    return (
        <>
            <div className='OEEDashboard_overlay-content' >
                <div id="myNav" className="overlay">
                    <span className="closebtn" onClick={closeFilter}>&times;</span>
                    {/* <span className="" onClick={closeFilter}>Choose Machine/Line...</span> */}
                    <div className="overlay-content">
                        <div className="filter-container" style={{ display: currentOpenedFilter === "Machine" ? 'block' : 'none' }}>
                            {
                                props.showMachines || props.showMachines === undefined ?
                                    <MachineSelector isMobile={true} onMachineSelected={closeFilter} loader={props.loader} setHide={props.setHide} />
                                    : ""
                            }
                        </div>
                        <div className="filter-container" style={{ display: currentOpenedFilter === "Shift" ? 'block' : 'none' }}>
                            {
                                props.showShifts || props.showShifts === undefined ? <Shifts isMobile={true} onShiftSelected={closeFilter} />
                                    : ""
                            }

                        </div>
                        <div className="filter-container" style={{ display: currentOpenedFilter === "Date" ? 'block' : 'none' }}>
                            {
                                props.showCalender || props.showCalender === undefined ?
                                    <CalendarWrapper selectedDate={selectedDate} onDateSelected={closeFilter} />
                                    : ""
                            }

                        </div>
                        <div className="filter-container" style={{ display: currentOpenedFilter === "autoRefresh" ? 'block' : 'none' }}>
                            {
                                props.showCalender || props.showCalender === undefined ?
                                    <Autorefresh isMobile={true}/>
                                    : ""
                            }

                        </div>
                    </div>
                </div>
            </div >
            <div className={themeName + ' oeeDashboard_inner_menu_bar'}>
                <div className={'oeeDashboard_MenuItems'}>
                    <span className={'oeeDashboard_MenuItems_text'} onClick={() => openFilter("autoRefresh")}>
                        <img src={Refresh} alt="" />
                    </span>
                    <span className={'oeeDashboard_MenuItems_text'} onClick={() => openFilter("Machine")}>
                        <img src={MachineSelection} alt="" />
                    </span>
                    <span className={'oeeDashboard_MenuItems_text'} onClick={() => openFilter("Shift")}>
                        <img src={Shift} alt="" />
                    </span>
                    <span className={'oeeDashboard_MenuItems_text'} onClick={() => openFilter("Date")}>
                        <img src={DatePicker} alt="" />
                    </span>
                </div>
            </div>
        </>



    )
}

function mapStateToProps(state) {
    return {
        filterStore: state.filterStore
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setSelectedDate
    }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(FilterPanelXS);
